import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          appBar: {
            siteName: 'Venue2You',
            logIn: 'Log in',
            logOut: 'Log out',
          },
          pages: {
            home: {
              underConstruction: 'This site is under construction',
              constructionImageAlt: 'construction sign',
            },
          },
        },
      },
    },
  })
  .catch((error) => console.log('i18n error:', error)); // eslint-disable-line no-console

export default i18n;
