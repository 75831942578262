import { makeObservable, observable, action } from 'mobx';

class AuthStore {
  apiKey?: string;

  constructor() {
    makeObservable(this, {
      apiKey: observable,
      setApiKey: action,
    });
  }

  setApiKey = (apiKey?: string): void => {
    this.apiKey = apiKey;
  };
}

export default AuthStore;
