import React from 'react';
import ReactDOM from 'react-dom';
import 'i18n';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import getAppProps from 'utils/get_app_props';
import RootStoreContext from 'contexts/root_store_context';
import RootStore from 'stores/root_store';
import MainContent from 'components/main_content';
import FlashToaster from 'components/shared/flash_toaster';

const rootStore = new RootStore();

const appTheme = createTheme({
  palette: {
    primary: {
      main: '#0d6efd',
      contrastText: 'white',
    },
  },
});

const App = ({
  apiKey,
  flashMessages,
}: {
  apiKey?: string;
  flashMessages: { [key: string]: Array<string> };
}) => {
  rootStore.authStore.setApiKey(apiKey);

  return (
    <RootStoreContext.Provider value={rootStore}>
      <ThemeProvider theme={appTheme}>
        <SnackbarProvider maxSnack={3}>
          <FlashToaster flashMessages={flashMessages} />
          <MainContent />
        </SnackbarProvider>
      </ThemeProvider>
    </RootStoreContext.Provider>
  );
};

App.defaultProps = {
  apiKey: null,
};

document.addEventListener('DOMContentLoaded', () => {
  const { apiKey, flashMessages } = getAppProps();

  ReactDOM.render(
    <App apiKey={apiKey} flashMessages={flashMessages} />,
    document.body.appendChild(document.createElement('div')),
  );
});
