import React from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AppBar from 'components/shared/app_bar';
import Home from 'components/home';

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: theme.mixins.toolbar,
}));

const MainContent = () => {
  const classes = useStyles();

  return (
    <div>
      <AppBar />
      <div className={classes.toolbar} />
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Home} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default MainContent;
