import * as React from 'react';
import { Observer } from 'mobx-react';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import useRootStore from '../../hooks/use_root_store';

const useStyles = makeStyles((theme: Theme) => ({
  barButton: {
    color: theme.palette.primary.contrastText,
    textTransform: 'none',
  },
}));

const LogInButton = ({ className }: { className: string }) => {
  const { t } = useTranslation();

  return (
    <Button href="/users/sign_in">
      <Typography variant="body1" component="div" className={className}>
        {t('appBar.logIn')}
      </Typography>
    </Button>
  );
};

const LogOutButton = ({ className }: { className: string }) => {
  const { t } = useTranslation();

  return (
    <Button data-method="delete" href="/users/sign_out">
      <Typography variant="body1" component="div" className={className}>
        {t('appBar.logOut')}
      </Typography>
    </Button>
  );
};

const AppBar = () => {
  const classes = useStyles();
  const rootStore = useRootStore();
  const { t } = useTranslation();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <MuiAppBar position="fixed" color="primary">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {t('appBar.siteName')}
          </Typography>
          <Observer>
            {() => {
              const {
                authStore: { apiKey },
              } = rootStore;

              return apiKey ? (
                <LogOutButton className={classes.barButton} />
              ) : (
                <LogInButton className={classes.barButton} />
              );
            }}
          </Observer>
        </Toolbar>
      </MuiAppBar>
    </Box>
  );
};

export default AppBar;
