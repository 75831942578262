import camelize from 'camelcase-keys';

interface FlashMessages {
  [key: string]: Array<string>;
}

export interface AppProps {
  apiKey: string | null | undefined;
  flashMessages: FlashMessages;
}

interface Window {
  [key: string]: any;
}

const getAppProps = (): AppProps => {
  const props = (window as Window).appProps || {};
  delete (window as Window).appProps;
  const dataScript = document.getElementById('react-app-props');
  dataScript.parentNode.removeChild(dataScript);
  return camelize(props);
};

export default getAppProps;
