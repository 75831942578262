import React from 'react';
import {
  Grid, Paper, Typography, Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import ConstructionSVG from 'images/construction_icon_yellow.svg';

const useStyles = makeStyles(() => ({
  imageBox: {
    textAlign: 'center',
  },
  image: {
    width: 200,
    minHeight: 200,
  },
}));

const Home = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper
      sx={{
        p: 2,
        margin: 'auto',
        maxWidth: 500,
        flexGrow: 1,
        marginTop: '1rem',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography variant="h2" textAlign="center">
                {t('pages.home.underConstruction')}
              </Typography>
            </Grid>
            <Grid item xs>
              <Box className={classes.imageBox}>
                <img
                  className={classes.image}
                  src={ConstructionSVG}
                  alt={t('pages.home.constructionImageAlt')}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Home;
