import React, { MouseEventHandler, useEffect } from 'react';
import { useSnackbar, VariantType } from 'notistack';
import { IconButton, Theme } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';

const allFlashMessages = (flashMessages: FlashMessages): Array<string> => {
  let messages: Array<string> = [];
  Object.values(flashMessages).forEach((arr) => {
    messages = messages.concat(arr);
  });

  return messages;
};

type FlashMessages = { [key: string]: Array<string> };

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    color: theme.palette.primary.contrastText,
  },
}));

const CloseButton = ({ onClick }: { onClick: MouseEventHandler }) => {
  const classes = useStyles();

  return (
    <div className={classes.closeButton}>
      <IconButton color="inherit" onClick={onClick}>
        <CancelIcon />
      </IconButton>
    </div>
  );
};

const FlashToaster = ({
  flashMessages,
}: {
  flashMessages: FlashMessages;
}): null => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const closeAction = (key) => (
      <CloseButton onClick={() => closeSnackbar(key)} />
    );
    const options = { variant: 'info' as VariantType, action: closeAction };
    allFlashMessages(flashMessages).forEach((message) => enqueueSnackbar(message, options));
  }, [flashMessages]);

  return null;
};

export default FlashToaster;
